
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

@import "src/assets/scss/variables";

::v-deep .hm-form {
  &__element {
    margin: 26px 0 0 0;
    @include for-size(phone-portrait-down) {
      margin: 20px 0 0 0;
    }
    p {
      margin: 0 0 18px 0;
    }
    a {
      color: #9466ff;
    }
    &--hidden {
      display: none;
    }
    &-label {
      margin: 0 0 8px 12px;
      transition: all ease 0.3s;
    }
    &-hint {
      margin: 8px 0 0 12px;
      font-size: 12px;
      line-height: 16px;
      transition: all ease 0.3s;
      &--error {
        color: $color-red;
      }
    }
    &-wrapper--error:not(.hm-form__database-element) {
      .hm-form__element-label, .hm-form__element-hint {
        color: $color-red;
      }
      .esmp-upload-drag {
        border-color: #ff0c0ccc;
      }
      .esmp-input .esmp-input-element,
      .esmp-textarea__input,
      .esmp-select-head {
        border: 1px #ff0c0ccc solid;
      }
    }
  }
  &__datepicker {
    display: flex;
    justify-content: flex-start;
  }
  &__datepicker-interval {
    flex-shrink: 0;
    width: 200px;
    margin-left: $base-gutter;
  }
}
